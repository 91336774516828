document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "users") {
   
        let tableUsers;
        let tableMemberships
        document.addEventListener("turbolinks:before-cache", function() {
            if (tableUsers != null) {
                tableUsers.destroy();
            }
        })

        tableUsers = $('#table-users').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'columnDefs': [
               // { "targets": 4, "visible": false },
            ],
            'order' : [[4,'desc'], [6,'desc']],
            "iDisplayLength": 50,
        });
        $("#table-users_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")
        $("#table-users_wrapper > .row > div:first").append("<div><span class='filter-planning_passed'>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-show_incompleted' checked>" + 
                "<label for='filter-show_incompleted'>Incompleted</label>" +
            "</div>" + 
            /*"<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-show_not_active' checked>" + 
                "<label for='filter-show_not_active'>Show inactive users</label>" +
            "</div>" + */
        "</span></div>")

        tableMemberships = $('#table-memberships').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
        });

        tablePayments = $('#table-payments').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
        });

        $("#filter-show_not_active").on("change", function() {
            if($(this).prop("checked")) {
                $.fn.dataTable.ext.search.pop();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                       return $(tableUsers.row(dataIndex).node()).attr('data-active') == 'true';
                    }
                 );
            }

            tableUsers.draw();
        })

        $(".edit-user").on("click", function(e) {
            user_id = $(this).parent().parent().attr("data-user_id")

            window.location = "/users/" + user_id + "/edit"
        })

        $(".deactivate-user").on("click", function() {
            user_id = $(this).parent().parent().attr("data-user_id")

            state = "active"
            if ($(`#table-users tr[data-user_id='${user_id}'] .state`).text() === "true") {
                state = "inactive"
            }

            Swal.fire({
                title: 'Are you sure?',
                text: `This user will be ${state}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change state!'
            }).then((result) => {
                if (result.isConfirmed) {
                    userActive(user_id)
                }
            })
        })

        // generate password
        $("#btn-generate_pwd").on("click", function() {
            if (!$("#user-password").attr("disabled")) {
                var length = 12,
                    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#!$",
                    retVal = "";
                for (var i = 0, n = charset.length; i < length; ++i) {
                    retVal += charset.charAt(Math.floor(Math.random() * n));
                }

                $("#user-password").val(retVal)
            }
        })
        
        $("#btn-reset-password").on("click", function() {
            $("#user-password").val($("#user-password").val().trim())
            
            $("#form-password").validate()

            if ($("#form-password").valid()) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "The user will receive an email contain the new password.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, reset it!'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        $(".alert-password").addClass("hidden")
                        resetPassword($("#user-id").val())
                    }
                  })
            }
        })
        

        $(".btn-choose_plan").on("click", function() {
         
            $("#plan_selected").val($(this).data("plan"))
            //window.location = "/users/order/" + $(this).data("plan") + "/" + $("#user_id").val()
            $(".btn-choose_plan").removeClass("choose-plan-selected")
            $(this).addClass("choose-plan-selected")

            $("#btn-create-account").attr('disabled', false)
        })
        
        $('#frm-user').submit(function(){
            if ($(this).valid()) {
                $(this).find('button[type=submit]').prop('disabled', true);
                $("#btn-create-account img").removeClass('hidden')
            }
        });
        
        $("#btn-create-account").on("click", function() {
            ///$("#btn-create-account").attr('disabled', true)
            //$("#btn-create-account img").removeClass('hidden')
        })

        // $('#expire_month').each(function () {
		// 	var el = $(this).get(0);
		// 	$(el).wrap('<div class="cs-wrapper"></div>');
		// 	new SelectFx(el);
		// });
        
        // $('#expire_year').each(function () {
		// 	var el = $(this).get(0);
		// 	$(el).wrap('<div class="cs-wrapper"></div>');
		// 	new SelectFx(el);
		// });

        $("#membership_assign_plan").select2({
            placeholder: "Assign Membership Plan",
            allowClear: false,
            minimumResultsForSearch: -1
        });


        $("#membership_assign_payment_method").select2({
            placeholder: "Assign Membership Plan",
            allowClear: false,
            minimumResultsForSearch: -1
        });
        
        $("#subscription_status").select2({
            placeholder: "Status",
            allowClear: false,
            minimumResultsForSearch: -1
        });

        $("#btn-assign_membership").on("click", function() {
            $("#modal-assign_membership").modal("show")
            //$("#membership_assign_plan").val($("#member-membership_plan option:selected").text())
            
        })

        $("#btn-edit_membership").on("click", function() {
            $("#modal-edit_membership").modal("show")
            
        })

        $("#membership_assign_payment_date, #membership_assign_start, #membership_assign_expire, #subscription_date_start, #subscription_date_end, #subscription-renewalDate").datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            multidate: false
        })

        $("#membership_assign_start").on("changeDate", function() {
            const date = $('#membership_assign_start').datepicker('getDate');
            let nextDayDate = new Date();
            nextDayDate.setDate(date.getDate() + 1);

            $("#membership_assign_expire").datepicker("setStartDate", nextDayDate)
        })
        
        $("#membership_assign_payment_method").on("change", function() {

            if ($(this).val() == 'transfer') {
                $(".membership_assign_payment_date").removeClass("hidden")
                $("#membership_assign_payment_date").attr('required', true)
                $(".membership_assign_expire").removeClass("hidden")
                $("#membership_assign_expire").attr('required', true)

                $(".membership_assign_payment_gifted_by").addClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', false)

                $(".membership_assign_payment_invoice_note").addClass("hidden")
            }
            else if ($(this).val() == 'gift') {
                $(".membership_assign_payment_date").addClass("hidden")
                $("#membership_assign_payment_date").removeAttr('required')
                $("#membership_assign_expire").removeClass("hidden")
                $(".membership_assign_expire").removeClass("hidden")
                $("#membership_assign_expire").attr('required', true)

                $(".membership_assign_payment_gifted_by").removeClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', true)

                $(".membership_assign_payment_invoice_note").addClass("hidden")
            }
            else if ($(this).val() == 'credit_card') {
                $(".membership_assign_payment_date").addClass("hidden")
                $("#membership_assign_payment_date").removeAttr('required')
                $(".membership_assign_expire").addClass("hidden")
                $("#membership_assign_expire").attr('required', false)

                $(".membership_assign_payment_gifted_by").addClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', false)

                $(".membership_assign_payment_invoice_note").addClass("hidden")
            }
            else if ($(this).val() == 'invoice') {
                $(".membership_assign_payment_date").removeClass("hidden")
                $("#membership_assign_payment_date").attr('required', true)
                $(".membership_assign_expire").removeClass("hidden")
                $("#membership_assign_expire").attr('required', true)

                $(".membership_assign_payment_gifted_by").addClass("hidden")
                $("#membership_assign_payment_gifted_by").attr('required', false)

                $(".membership_assign_payment_invoice_note").removeClass("hidden")
            }
        })


        $("#btn-save-edit_membership").on("click", function() {

            $("#form-edit_membership").validate()
            $('#form-edit_membership').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-edit_membership").valid()) {
                let formData = new FormData();
                formData.append('user_id', $("#user-id").val());
                formData.append('status', $("#subscription_status option:selected").val());
                formData.append('start', $("#subscription_date_start").val());
                formData.append('expire', $("#subscription_date_end").val());

                formData.append('notificationType', $("#subscription-notificationType").val());
                formData.append('subtype', $("#subscription-subtype").val());
                formData.append('transactionId', $("#subscription-transactionId").val());
                formData.append('originalTransactionId', $("#subscription-originalTransactionId").val());
                formData.append('renewalDate', $("#subscription-renewalDate").val());
                formData.append('transactionReason', $("#subscription-transactionReason").val());


                Swal.fire({
                    title: 'Membership plan',
                    text: `Update this membership plan?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return fetch(`/users/edit-status-plan`, {
                            method: 'PUT',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            if (response.ok) {
                                response.json().then(function(data) {
                                    console.log(data)
                                    $("#modal-edit_membership").modal("hide")
                                    $("#btn-save-edit_membership").html("<i class='fa-solid fa-floppy-disk mr-2'></i> Save")
                                    $("#btn-save-edit_membership").attr("disabled", false)
                                    
                                    $(`#table-memberships tr[data-subscription_id='${data.id}'] td:nth-child(5)`).html(data.status_decorate)
                                    $(`#table-memberships tr[data-subscription_id='${data.id}'] td:nth-child(3)`).html(moment(data.start).format("LL"))
                                    $(`#table-memberships tr[data-subscription_id='${data.id}'] td:nth-child(4)`).html(moment(data.expire).format("LL"))

                                    Swal.fire(
                                        'Membership status updated!',
                                        '',
                                        'success'
                                    )
                                })
                            }
                            else {
                                response.json().then(function(data) {

                                })
                            }
                        })
                    }
                }).then((result) => {})
            }
        })

        $("#btn-save-assign_membership").on("click", function() {

            $("#form-assign_membership").validate()
            $('#form-assign_membership').on("submit", function(e) {
                e.preventDefault()
            })

            if ($("#form-assign_membership").valid()) {

                $(".error-assign_membership").addClass("hidden")
                let formData = new FormData();
                formData.append('user_id', $("#user-id").val());
                formData.append('membership_plan_id', $("#membership_assign_plan").val());
                formData.append('payment_method', $("#membership_assign_payment_method option:selected").val());
                formData.append('payment_date', $("#membership_assign_payment_date").val());
                //formData.append('start', $("#membership_assign_start").val());
                //formData.append('expire', $("#membership_assign_expire").val());
                if ($("#membership_assign_payment_method option:selected").val() == 'gift') {
                    formData.append('gifted_by', $("#membership_assign_payment_gifted_by").val());
                }
                if ($("#membership_assign_payment_method option:selected").val() == 'invoice') {
                    formData.append('invoice_note', $("#membership_assign_payment_invoice_note").val());
                }

                let until = ""
                if ($("#membership_assign_payment_method").val() != 'stripe') {
                    until = `until ${$('#membership_assign_expire').val()}`
                }
                else if ($("#membership_assign_payment_method").val() == 'stripe') {
                    until = ''
                }

                Swal.fire({
                    title: 'Membership plan',
                    text: `Are you sure to assign ${$('#membership_assign_plan').val()} to this member?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, assign it!',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return fetch(`/users/assign-plan`, {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            if (response.ok) {
                                response.json().then(function(data) {
                                    $("#modal-assign_membership").modal("hide")
                                    $("#btn-save-assign_membership").html("<i class='fa-solid fa-floppy-disk mr-2'></i> Save")
                                    $("#btn-save-assign_membership").attr("disabled", false)
                                    $("select[name='member[status]']").val('1') 
                          
                                    if (tableMemberships.data().count() > 0) {
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(1)`).text(data.subscription.id)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(2)`).text(data.subscription.membership_plan.name)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(3)`).text(data.subscription.amount.cents)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(5)`).text(moment.utc(data.subscription.start).format("LL"))
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(6)`).text(moment.utc(data.subscription.expire).format("LL"))
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(6)`).text(data.subscription.payment_method_decorate)
                                        $(`#table-membership_plans tr[data-subscription_id='${data.subscription.id}'] td:nth-child(7)`).html(data.subscription.status_decorate)
                                    }
                                    else {
                                        // let newRow = table_membership_plans.row.add( [
                                        //     data.subscription.membership_plan.name,
                                        //     data.subscription.price,
                                        //     moment.utc(data.subscription.start).format("LL"),
                                        //     moment.utc(data.subscription.expire).format("LL"),
                                        //     data.subscription.payment_method_decorate,
                                        //     '',
                                        //     data.subscription != null ? data.subscription.status_decorate : '',
                                        //     ''
                                        // ] ).draw( false ).node();

                                        // $(newRow).attr("data-subscription_id", data.subscription.id);
                                    }
                                    let expire;
                                    if (data.membership_plan.access_recurring == 'day') {
                                        expire = moment.utc(data.transaction.created_at).add(1, 'days').format("LL")
                                    }
                                    else if (data.membership_plan.access_recurring == 'month') {
                                        expire = moment.utc(data.transaction.created_at).add(1, 'month').format("LL")
                                    }
                                    else if (data.membership_plan.access_recurring == 'year') {
                                        expire = moment.utc(data.transaction.created_at).add(1, 'year').format("LL")
                                    }

                                    if (data.transaction.payment_method == 'gift') {
                                        let newRow = tableMemberships.row.add( [
                                            data.membership_plan.name,
                                            data.transaction.price,
                                            moment.utc(data.transaction.created_at).format("LL"),
                                            expire,
                                            `${data.transaction.payment_method} (by: ${data.transaction.gifted_by})`,
                                            'Active'
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.transaction.id);
                                    }

                                    if (data.transaction.payment_method == 'transfer') {
                                        let newRow = tableMemberships.row.add( [
                                            data.membership_plan.name,
                                            data.transaction.price,
                                            moment.utc(data.transaction.created_at).format("LL"),
                                            expire,
                                            '',
                                            'Active',
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Delete subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.transaction.id);
                                        
                                        // create order
                                        /*newRow = tablePayments.row.add( [
                                            data.subscription.membership_plan.name,
                                            data.order.amount_due,
                                            data.order.amount_paid,
                                            data.order.description,
                                            moment.utc(data.order.start).format("LL"),
                                            data.order.status_decorate
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-element_id", data.order.id);*/
                                    }

                                    if (data.transaction.payment_method == 'invoice') {

                                        let newRow = tableMemberships.row.add( [
                                            data.transaction.id,
                                            data.membership_plan.name,
                                            data.transaction.price,
                                            moment.utc(data.transaction.created_at).format("LL"),
                                            expire,
                                            data.transaction.payment_method,
                                            '',
                                            'Active',
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Cancel subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.transaction.id);

                                    }

                                    if (data.transaction.payment_method == 'stripe invoice') {
                                        let newRow = tableMemberships.row.add( [
                                            data.subscription.id,
                                            data.subscription.membership_plan.name,
                                            data.subscription.price,
                                            moment.utc(data.subscription.start).format("LL"),
                                            moment.utc(data.subscription.expire).format("LL"),
                                            data.subscription.payment_method_decorate,
                                            '',
                                            data.subscription.status_decorate,
                                            `<a href="javascript:" class="delete-element subscription-delete" title="Delete subscription"><i class="fa-solid fa-trash-can"></i></a>`
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-subscription_id", data.subscription.id);

                                        // create order
                                        newRow = tablePayments.row.add( [
                                            data.subscription.membership_plan.name,
                                            data.order.amount_due,
                                            data.order.amount_paid,
                                            data.order.description,
                                            moment.utc(data.order.start).format("LL"),
                                            data.order.status_decorate
                                        ] ).draw( false ).node();

                                        $(newRow).attr("data-element_id", data.order.id);
                                    }

                                    // $("table tbody").on("click", '.subscription-delete', function() {
                                    //     id = $(this).parent().parent().data("subscription_id")
                            
                                    //     deleteSubscription(data.subscription.id)
                                    // })
                                    
                                    Swal.fire(
                                        'Subscription created!',
                                        '',
                                        'success'
                                    )
                                })
                            }
                            else {
                                response.json().then(function(data) {
                                    $(".error-assign_membership").removeClass("hidden")
                                    $(".error-assign_membership").html(data.error)
                                    $("#btn-save-assign_membership").html("<i class='fa-solid fa-floppy-disk mr-2'></i> Save")
                                    $("#btn-save-assign_membership").attr("disabled", false)
                                })
                            }
                        })
                    }
                }).then((result) => {})
            }
        })

        $("#btn-next").on("click", function() {
            
            if ($(".active").hasClass("plan_zone") && !$(".btn-choose_plan").hasClass("choose-plan-selected")) {
                return
            }

            if (!$("#user_first_name").valid() || !$("#user_last_name").valid() || !$("#user_email").valid() || !$("#user_password").valid() || !$("#user_confirm_password").valid()) {
                return
            }
            
            $(".active").removeClass("active").addClass("hidden").next().addClass("active").removeClass("hidden")
            $("#btn-previous").attr("disabled", false)

            if ($(".active").hasClass("payment_zone")) {
               // $("#btn-create-account").removeClass("hidden")
                $("#btn-next").attr("disabled", true)

                $("#btn_generate_payment_url").removeClass("hidden")

                $(".name_preview").text($("#user_first_name").val() + " " + $("#user_last_name").val())
                $(".email_preview").text($("#user_email").val())
                $(".plan_preview").text($(".choose-plan-selected h5:first-child").text() + "(" + $(".choose-plan-selected h5:last-child").text() + ")")
            }
            else {
                $("#btn-create-account").addClass("hidden")
                $("#btn-next").attr("disabled", false)

                $("#btn_generate_payment_url").addClass("hidden")
            }

        })

        $("#btn-previous").on("click", function() {
            $(".active").removeClass("active").addClass("hidden").prev().addClass("active").removeClass("hidden")
            $("#btn-next").attr("disabled", false)
            $("#btn-create-account").addClass("hidden")

            if ($(".active").hasClass("personal_zone")) {
                $("#btn-previous").attr("disabled", true)
            }
        })


        $("#btn_generate_payment_url").on("click", function() {

            $("#btn_generate_payment_url img").removeClass("hidden")
            $(this).attr("disabled", true)
            $("#btn-previous").attr("disabled", true)
            
            /*fetch(`https://secure-global.paytabs.com/payment/request`, {
                method: 'POST',
                headers: {
                    'Authorization': `SRJ9M6HDZW-J6ZWRRMWD2-DNT6KRWRR2`,
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify({
                    "profile_id": 128621,
                    "tran_type": "sale",
                    "tran_class": "ecom",
                    "cart_id": "CART#2101",
                    "cart_currency": "USD",
                    "cart_amount": 1,
                    "cart_description": "Description of the items/services",
                    "customer_details": {
                    "name": "Chedly makhlouf",
                    "email": "chedly.makhlouf@gmail.com",
                            "phone": "+21651089996",
                    "street1": "tunis",
                    "city": "Tunis",
                        "state": "Tunis",
                    "country": "TN",
                            "zip": "0000",
                        "ip" : "197.244.144.245"
                    }
                })
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                      console.log(data)
                    })
                }
                else {
                    console.log(response)
                }
            })*/

            $.ajax({
                url: "/users/generate_paytab_payment_url",
                type: 'GET',
                mode: 'cors',
                cache: 'default',
                dataType: "json",
                data: {
                    name: $("#user_first_name").val() + " " + $("#user_last_name").val(),
                    email: $("#user_email").val(),
                    plan_id: $(".choose-plan-selected").data("plan"),
                    plan: $(".choose-plan-selected h5:first-child").text(),
                    price: $(".choose-plan-selected h5:last-child").text(),
                    user: {
                        first_name: $("#user_first_name").val(),
                        last_name: $("#user_last_name").val(),
                        email: $("#user_email").val(),
                        password: $("#user_password").val(),
                        confirm_password: $("#user_confirm_password").val()
                    }
                },
                success: function (data) {
                    //window.open(data.redirect_url, 'new');
                    window.location = data.redirect_url
                },
                error: function(data) {
                    location.reload()
                }
            });
        })

        function userActive(user_id) {
   
            let active = true;
            if ($(`#table-users tr[data-user_id='${user_id}'] .state`).text() === "true") {
                active = false
            }
            
            $.ajax({
                url: "/users/" + user_id,
                type: 'PUT',
                dataType: "json",
                data:{
                    "user":{
                        active: active
                    }
                },
                success: function (data) {
                   
                    if (active) {
                        $(`#table-users tr[data-user_id='${user_id}']`).removeClass("tr-deactivated")
                        $(`#table-users tr[data-user_id='${user_id}']`).attr("data-active", true)
                        $(`#table-users tr[data-user_id='${user_id}'] td:nth-child(6)`).html("<span class='badge badge-success state'>true</span>");
                    }
                    else  {
                        $(`#table-users tr[data-user_id='${user_id}']`).addClass("tr-deactivated")
                        $(`#table-users tr[data-user_id='${user_id}']`).attr("data-active", false)
                        $(`#table-users tr[data-user_id='${user_id}'] td:nth-child(6)`).html("<span class='badge badge-warning state'>false</span>");
                    }

                    Swal.fire(
                        'Updated!',
                        'User has been activated.',
                        'success'
                    )
                },
                error: function(data) {
                }
            });
        }
        
        // change state
        $(".change-state").on("click", function() {
            user_id = $(this).parent().parent().attr("data-user_id")

            let name = $('.user[data-user_id=' + user_id + '] td:nth-child(2)').text() + " " + $('.user[data-user_id=' + user_id + '] td:nth-child(3)').text()
            let title = $(this).text() == "true" ? 'Inactive' : 'Active'
            let text = $(this).text() == "false" ? `${name} could be able to login again` : `${name} will no longer be able to connect`
            let state = $(this).text() == "true" ? false : true

            Swal.fire({
                title: `Change this user's state to ${title} ?`,
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        changeState(user_id, state)
                    }
              })
        })

        function changeState(user_id, state) {
            $.ajax({
                url: "/users/" + user_id,
                type: 'PUT',
                dataType: "json",
                data: {
                    user: {
                        'active': state
                    }
                },
                success: function (data) {
                    $(`#table-users tr[data-user_id='${user_id}'] .change-state`).text(state);
                    $(`#table-users tr[data-user_id='${user_id}']`).toggleClass("tr-deactivated")
                    if (state) {
                        $(`#table-users tr[data-user_id='${user_id}'] .change-state`).addClass("badge-success")
                        $(`#table-users tr[data-user_id='${user_id}'] .change-state`).removeClass("badge-warning")
                        $(`#table-users tr[data-user_id='${user_id}']`).attr("data-active", true)
                    }
                    else {
                        $(`#table-users tr[data-user_id='${user_id}'] .change-state`).addClass("badge-warning")
                        $(`#table-users tr[data-user_id='${user_id}'] .change-state`).removeClass("badge-success")
                        $(`#table-users tr[data-user_id='${user_id}']`).attr("data-active", false)
                    }
                },
                error: function(data) {
                }
            });
        }

        function resetPassword(user_id) {
            $(".loader-reset_password").removeClass("hidden")
            $("#btn-reset-password").attr("disabled", true)
            
            $.ajax({
                url: "/users/" + user_id + "/reset_password",
                type: 'PUT',
                dataType: "json",
                data: {
                    password: $("#user-password").val()
                },
                success: function (data) {
                    $("#user-password").val("")
                    $(".loader-reset_password").addClass("hidden")

                    Swal.fire(
                        'Updated!',
                        "User's password has been updated and sent successfully!",
                        'success'
                    )
                },
                error: function(data) {
                    $(".alert-password").removeClass("hidden")
                    $(".loader-reset_password").addClass("hidden")
                    $("#btn-reset-password").attr("disabled", false)

                    $(".alert-password").text("")
                    data.responseJSON.forEach(function(e) {
                        $(".alert-password").append(e)
                    });

                 
                }
            });
        }
        
        
    }
})